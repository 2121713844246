import {
  Accordion,
  AccordionBody,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Input,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import { FC, useEffect, useRef, useState } from "react";
import useFetch from "use-http";
import { Config } from "../config";
import { Recipe, User } from "../domain";
import { GetUserPath } from "../pages/Home";
import FAB from "./FAB";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faReceipt,
  faArrowLeft,
  faFilter,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

interface ProbabilityWeightProps {
  weight: number;
  description: string;
}

interface RecipeTabProps {
  user: User | null;
}
const RecipeTab: FC<RecipeTabProps> = ({ user }) => {
  const portionsSizeRef = useRef<HTMLInputElement>(null);
  const portionSizes = [2, 4, 6, 8];
  const [selectedPortionSize, setSelectedPortionSize] = useState<number>(4);
  const probabilityWeightRef = useRef<HTMLInputElement>(null);
  const probabilityWeights = ["1/7", "1/14", "1/21", "1/28"];
  const [selectedProbabilityWeight, setSelectedProbabilityWeight] =
    useState<string>("1/21");
  const probablityMap: Record<string, ProbabilityWeightProps> = {
    "1/7": {
      weight: 1 / 7,
      description: "Once a week",
    },
    "1/14": {
      weight: 1 / 14,
      description: "Once every two weeks",
    },
    "1/21": {
      weight: 1 / 21,
      description: "Once every three weeks",
    },
    "1/28": {
      weight: 1 / 28,
      description: "Once a month",
    },
    custom: {
      weight: 0,
      description: "Pick a custom frequency",
    },
  };
  interface NewRecipe {
    name: string;
    url: string;
  }
  const [newRecipe, setNewRecipe] = useState<NewRecipe>({
    name: "",
    url: "",
  });
  const [leftOverCompliance, setLeftOverCompliance] = useState<boolean>(false);

  const options: any = {
    headers: {
      "Content-Type": "application/json",
    },
    cachePolicy: "no-cache",
  };
  const { post, get, loading, error, response } = useFetch(
    `${Config.ApiUrl()}`,
    options,
  );

  const addRecipe = async () => {
    let portions = selectedPortionSize;
    let probabilityWeight = probablityMap[selectedProbabilityWeight].weight;
    if (selectedProbabilityWeight === "custom") {
      probabilityWeight = parseFloat(
        probabilityWeightRef.current?.value ?? "0",
      );
    }
    if (selectedPortionSize === -1) {
      portions = parseInt(portionsSizeRef.current?.value ?? "0");
    }
    const recipe = {
      name: newRecipe.name,
      portions: portions,
      probability_weight: probabilityWeight,
      url: newRecipe.url,
      left_over_compliance: leftOverCompliance,
    };
    await post(`${GetUserPath(user)}/recipes`, recipe);
    if (response.status === 201) {
      getRecipes();
      setNewRecipe({
        name: "",
        url: "",
      });
    }
  };

  const filterInputRef = useRef<HTMLInputElement>(null);
  const [toggleFilter, setToggleFilter] = useState<boolean>(false);
  const [toggleCreate, setToggleCreate] = useState<boolean>(false);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [displayedRecipes, setDisplayedRecipes] = useState<Recipe[]>([]);
  const [selectedID, setSelectedID] = useState<string>("");
  const getRecipes = async () => {
    const data = await get(`${GetUserPath(user)}/recipes`);
    if (response.status === 200) {
      setRecipes(data);
      setDisplayedRecipes(data);
    }
  };
  useEffect(() => {
    if (user) {
      getRecipes();
    }
  }, [user, get]);

  return (
    <TabPanel className="p-0" value="recipes">
      {!toggleCreate && (
        <>
          <div className="flex items-centered h-8 absolute top-6 right-6">
            {loading && <p>Loading...</p>}{" "}
          </div>
          {error && <p>Error!</p>}
          {recipes && recipes.length === 0 && !loading && (
            <p className="px-4 pt-2 text-xl">
              You don't have any recipes yet. Click the green button to add
            </p>
          )}
          <div className="flex flex-col justify-around">
            {displayedRecipes && (
              <>
                <div className="flex justify-between px-4 mt-4">
                  <p className="text-2xl font-bold pr">
                    Found {recipes.length} recipes
                  </p>
                  <span>
                    <FontAwesomeIcon
                      className="text-2xl"
                      icon={faFilter}
                      onClick={() => {
                        if (!toggleFilter) {
                          setTimeout(() => {
                            filterInputRef.current?.focus();
                          }, 100);
                        }
                        setToggleFilter(!toggleFilter);
                      }}
                    />
                  </span>
                </div>
                {toggleFilter && (
                  <div className="flex flex-col gap-4 px-3 relative">
                    <input
                      ref={filterInputRef}
                      type="text"
                      placeholder="Filter.."
                      className="placeholder:translate-y-[2px] border border-gray-500 rounded-md px-4 py-1 w-full focus:outline-gray-700 text-green-500 font-bold"
                      onChange={(e) => {
                        const search = e.target.value;
                        const filteredRecipes = recipes.filter((r: Recipe) => {
                          const matchStr = `${r.name}${r.url ?? ""}`;
                          return matchStr
                            .toLowerCase()
                            .includes(search.toLowerCase());
                        });
                        setDisplayedRecipes(filteredRecipes);
                      }}
                    />
                    <span>
                      <FontAwesomeIcon
                        className="text-2xl absolute right-6 top-[2px] my-1"
                        icon={faXmark}
                        onClick={() => {
                          filterInputRef.current!.value = "";
                          filterInputRef.current!.focus();
                          setDisplayedRecipes(recipes);
                        }}
                      />
                    </span>
                  </div>
                )}
              </>
            )}
            {displayedRecipes &&
              Array.isArray(displayedRecipes) &&
              displayedRecipes.map((r: Recipe) => (
                <Accordion
                  onClick={() => {
                    setSelectedID(r.id);
                  }}
                  className="py-2"
                  key={r.id}
                  open={selectedID == r.id}
                >
                  <div className="px-4 text-md flex justify-around">
                    <span>{r.name}</span>
                    <span className="ml-auto text-muted text-sm">
                      {r.portions} portions
                    </span>
                  </div>
                  <AccordionBody className="bg-gray-100 px-4 py-2 flex flex-col">
                    <div>
                      {r.probability_weight.toFixed(2)} probability weight
                    </div>
                    <div>
                      {r.left_over_compliance
                        ? "Suitable for reheating the next day"
                        : "Not suitable for reheating the next day"}
                    </div>
                    {r.url && (
                      <a
                        href={r.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500"
                      >
                        {r.url}
                      </a>
                    )}
                  </AccordionBody>
                </Accordion>
              ))}
          </div>
          <div className="flex flex-col justify-between fixed right-6 bottom-6 w-14">
            <FAB
              onClick={() => {
                setToggleCreate(!toggleCreate);
              }}
              className="bg-green-500 h-14 w-14"
            >
              <FontAwesomeIcon className="text-2xl" icon={faReceipt} />
            </FAB>
          </div>
        </>
      )}
      {toggleCreate && (
        <>
          <div className="block w-full px-4 py-2 mt-1">
            <FontAwesomeIcon
              className="text-2xl"
              icon={faArrowLeft}
              onClick={() => {
                setToggleCreate(!toggleCreate);
              }}
            />
          </div>
          <Card className="w-96 mx-auto mt-6">
            <CardHeader
              variant="gradient"
              color="green"
              className="grid h-20 place-items-center"
            >
              <Typography variant="h3" color="white">
                Add a recipe
              </Typography>
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
              <span className="text-gray-500 text-md font-bold">
                What is the recipe called?
              </span>
              <Input
                value={newRecipe.name}
                onChange={(e) => {
                  setNewRecipe({ ...newRecipe, name: e.target.value });
                }}
                label="Recipe name"
                size="lg"
                crossOrigin={undefined}
                className="focus:outline-green-500"
                color="green"
              />
              <span className="text-gray-500 text-md font-bold">
                How many portions?
              </span>
              <div className="flex gap-4">
                {portionSizes.map((portionSize) => {
                  return (
                    <Button
                      key={portionSize}
                      variant={
                        portionSize === selectedPortionSize
                          ? "filled"
                          : "outlined"
                      }
                      color="green"
                      className="text-lg font-bold px-4 py-2"
                      onClick={() => {
                        setSelectedPortionSize(portionSize);
                      }}
                    >
                      {portionSize}
                    </Button>
                  );
                })}
                <input
                  ref={portionsSizeRef}
                  onFocus={() => {
                    setSelectedPortionSize(-1);
                  }}
                  type="text"
                  placeholder="x"
                  className="border border-green-500 rounded-md px-2 py-2 w-14 focus:outline-green-700 text-center text-green-500 font-bold"
                />
              </div>
              <span className="text-gray-500 text-md font-bold">
                How often would you like it? (days)
              </span>
              <div className="flex gap-4">
                {probabilityWeights.map((pw: string) => {
                  return (
                    <Button
                      key={pw}
                      variant={
                        pw === selectedProbabilityWeight ? "filled" : "outlined"
                      }
                      color="green"
                      className="text-md font-bold px-2 py-2 focus:outline-green-700"
                      onClick={() => {
                        setSelectedProbabilityWeight(pw);
                      }}
                      title={probablityMap[pw].description}
                    >
                      {pw}
                    </Button>
                  );
                })}
                <input
                  ref={probabilityWeightRef}
                  onFocus={() => {
                    setSelectedProbabilityWeight("custom");
                  }}
                  type="text"
                  placeholder="x"
                  className="border border-green-500 rounded-md px-2 py-2 w-14 focus:outline-green-700 text-center text-green-500 font-bold"
                />
              </div>
              <div className="flex">
                <span className="text-green-500 text-md font-bold">
                  {probablityMap[selectedProbabilityWeight].description}
                </span>
              </div>
              <span className="text-gray-500 text-md font-bold">
                Recipe url? (optional)
              </span>
              <Input
                value={newRecipe.url}
                onChange={(e) => {
                  setNewRecipe({ ...newRecipe, url: e.target.value });
                }}
                label="URL"
                size="lg"
                crossOrigin={undefined}
                className="focus:outline-green-500"
                color="green"
              />
              <div className="-ml-2.5">
                <Checkbox
                  checked={leftOverCompliance}
                  onChange={() => {
                    setLeftOverCompliance(!leftOverCompliance);
                  }}
                  label="Mark as left-over compliant"
                  crossOrigin={undefined}
                  color="green"
                />
              </div>
            </CardBody>
            <CardFooter className="pt-0">
              <Button
                variant="gradient"
                color="green"
                className="flex items-center justify-center h-12"
                fullWidth
                onClick={() => {
                  addRecipe();
                }}
              >
                Add recipe
              </Button>
            </CardFooter>
          </Card>
        </>
      )}
    </TabPanel>
  );
};
export default RecipeTab;
