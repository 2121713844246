import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import { Provider } from "use-http";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Start from "./pages/Start";
import { useState } from "react";
import Admin from "./pages/Admin";

const App = () => {
  const options = {
    interceptors: {
      request: async ({ options }: any) => {
        const token = localStorage.getItem("token");
        if (token && token.length > 0) {
          options.headers.Authorization = `Bearer ${token}`;
        }
        return options;
      },
      // every time we make an http request, before getting the response back, this will run
      response: async ({ response }: any) => {
        const res = response;
        if (res.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        return res;
      },
    },
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Provider url="http://meals.cjiq.se" options={options}>
              <Start />
            </Provider>
          }
        />
        <Route
          path="/home"
          element={
            <Provider url="http://meals.cjiq.se" options={options}>
              <Home />
            </Provider>
          }
        />
        <Route
          path="/admin"
          element={
            <Provider options={options}>
              <Admin />
            </Provider>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
