import { FC, useState } from "react";
import { User } from "../domain";
import UserIcon from "./icons/UserIcon";

interface UserCardProps {
  user: User | null;
}

const UserCard: FC<UserCardProps> = ({ user }) => {
  const [userState, _] = useState<User>(() => {
    if (user === null) {
      user = {
        name: "Guest",
        id: "",
        username: "guest",
      };
    }
    return user;
  });
  const [showMenu, setShowMenu] = useState<boolean>(false);
  return (
    <>
      <div className="flex relative w-screen items-center h-full">
        <div
          className="absolute right-4"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <UserIcon />
        </div>

        {showMenu && (
          <div className="flex flex-col absolute right-3 top-11 z-50 p-2 rounded w-auto bg-gray-300">
            <span className="border-b-gray-100">Hello, {userState.name}!</span>
            <div className="flex">
              {userState.username === "cjiq" && (
                <span
                  className="ml-auto text-indigo-500 hover:underline"
                  onClick={() => {
                    window.location.href = "/admin";
                  }}
                >
                  Admin
                </span>
              )}
              <span
                className="ml-auto text-indigo-500 hover:underline"
                onClick={() => {
                  window.location.href = "/logout";
                }}
              >
                Logout
              </span>
            </div>
          </div>
        )}
      </div>
      {showMenu && (
        <div
          className="absolute h-screen w-screen -top-14 mt-14 z-40"
          onClick={() => {
            setShowMenu(false);
          }}
        ></div>
      )}
    </>
  );
};

export default UserCard;
