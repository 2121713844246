import { IconButton } from "@material-tailwind/react";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

export interface FABProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const FAB: FC<FABProps> = ({ className = "", children, onClick }) => {
  const classes = twMerge(
    "rounded-full bg-transparent flex items-centered",
    className,
  );
  return (
    <div className={classes} onClick={onClick}>
      <IconButton className="rounded-full bg-transparent max-w-none max-h-none w-full h-full">
        {children}
      </IconButton>
    </div>
  );
};
export default FAB;
