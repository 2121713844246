import { FC, useEffect, useState } from "react";
import useFetch from "use-http";
import { Config } from "../config";
import { Day, User, Week } from "../domain";
import { Button, TabPanel } from "@material-tailwind/react";
import dayjs from "dayjs";
import TrashIcon from "./icons/TrashIcon";
import { Accordion, AccordionBody } from "@material-tailwind/react";
import SaveIcon from "./icons/SaveIcon";
import FAB from "./FAB";
import { GetUserPath } from "../pages/Home";

export interface WeeksTabProps {
  user: User | null;
  initialWeeks: Week[];
  updateSharedWeeks: (weeks: Week[]) => void;
}

const WeeksTab: FC<WeeksTabProps> = ({
  user,
  initialWeeks = [],
  updateSharedWeeks = () => {},
}) => {
  const [selectedID, setSelectedID] = useState<string>("");
  const options: any = {
    headers: {
      "Content-Type": "application/json",
    },
    cachePolicy: "no-cache",
  };
  const { get, post, put, del, loading, error, response } = useFetch(
    `${Config.ApiUrl()}`,
    options,
  );
  useEffect(() => {
    fetchWeeks();
  }, []);
  const fetchWeeks = async () => {
    const data = await get(`${GetUserPath(user)}/weeks/2023`);
    if (data) {
      setWeeks(deepCopy(data));
      setOrgWeeks(deepCopy(data));
      updateSharedWeeks(deepCopy(data));
    }
  };
  const deleteWeek = (id: string) => async () => {
    await del(`${GetUserPath(user)}/weeks/${id}`);
    if (response.status === 204) {
      fetchWeeks();
    }
  };

  const setWeekStates = (weeks: Week[]) => {
    setOrgWeeks(deepCopy(weeks));
    setWeeks(deepCopy(weeks));
  };

  const updateWeeks = async () => {
    const data = await put(`${GetUserPath(user)}/weeks`, weeks);
    if (data) {
      setWeekStates(data);
    }
  };

  const deepCopy = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
  };

  const swapRecipe = async (
    weekId: string,
    day: Day,
    weekIndex: number,
    dayIndex: number,
  ) => {
    const data = await post(
      `${GetUserPath(user)}/weeks/${weekId}/suggest`,
      day,
    );
    if (data) {
      const newRes = data;
      const oldDay = weeks[weekIndex].days[dayIndex];
      oldDay.dinner = newRes;

      const updatedWeek = [...weeks];
      updatedWeek[weekIndex].days[dayIndex] = oldDay;

      setWeeks(updatedWeek);
    }
  };

  const changeExists = () => {
    const orgWeeksJSON = JSON.stringify(orgWeeks);
    const weeksJSON = JSON.stringify(weeks);
    const res = orgWeeksJSON !== weeksJSON;
    return res;
  };

  useEffect(() => {
    setWeekStates(initialWeeks);
  }, [initialWeeks]);

  const [orgWeeks, setOrgWeeks] = useState<Week[]>([]);
  const [weeks, setWeeks] = useState<Week[]>(initialWeeks);
  return (
    <>
      <TabPanel className="p-0" value="weeks">
        <div className="flex items-centered h-8 absolute top-6 right-6">
          {loading && <p>Loading...</p>}
        </div>
        {error && <p>Error!</p>}
        {weeks && weeks.length === 0 && !loading && (
          <p className="px-4 pt-2 text-xl">
            You have no planned weeks. Do you wish to create some? Click the
            "Generate" tab at to get started.
          </p>
        )}
        {weeks &&
          Array.isArray(weeks) &&
          weeks.map((week: Week, weekIndex: number) => {
            return (
              <div
                key={week.id}
                className="h-[calc(100vh-200px)] flex flex-col justify-around"
              >
                <div className="flex justify-between px-4 mt-2">
                  <p className="text-2xl font-bold pr">
                    V.{week.number} ({week.year})
                  </p>
                  <div className="ml-auto flex">
                    <span onClick={deleteWeek(week.id)} className="">
                      <TrashIcon />
                    </span>
                  </div>
                </div>
                {week.days.map((day: Day, dayIndex: number) => {
                  const date = dayjs(day.date);
                  const dateIsToday =
                    date.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");
                  const dayClass =
                    "flex flex-col justify-between px-4 py-2 active:bg-gray-200";
                  return (
                    <Accordion key={day.id} open={selectedID == day.id}>
                      <div
                        onClick={() => {
                          setSelectedID(day.id);
                        }}
                        className={dayClass}
                      >
                        <div className="flex justify-between items-center">
                          <span className="text-xl font-bold">
                            {date.format("dddd")} {dateIsToday && "*"}
                          </span>
                          <span className="text-sm text-gray-500">
                            {date.format("YYYY-MM-DD")}
                          </span>
                        </div>
                        <p className="ml-3">{day.dinner.name}</p>
                      </div>
                      <AccordionBody className="bg-gray-100 px-4 py-2 flex items-center justify-between">
                        <div className="flex flex-col">
                          <p>Portions: {day.dinner.portions}</p>
                          {day.dinner.url && (
                            <a
                              title={day.dinner.url}
                              href={day.dinner.url}
                              target="_blank"
                              className="text-blue-500 block hover:underline"
                            >
                              {day.dinner.url.substring(0, 24)}...
                            </a>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            swapRecipe(week.id, day, weekIndex, dayIndex);
                          }}
                        >
                          <Button className="bg-blue-400">
                            New suggestion?
                          </Button>
                        </div>
                      </AccordionBody>
                    </Accordion>
                  );
                })}
                {changeExists() && (
                  <FAB
                    onClick={() => {
                      updateWeeks();
                    }}
                    className="fixed bottom-6 right-6 bg-green-500 h-14 w-14"
                  >
                    <SaveIcon className="w-8 h-8" />
                  </FAB>
                )}
              </div>
            );
          })}
      </TabPanel>
    </>
  );
};

export default WeeksTab;
