import { FC, useEffect, useState } from "react";
import useFetch from "use-http";
import { Config } from "../config";
import { Day, User, Week } from "../domain";
import { Button, TabPanel } from "@material-tailwind/react";
import dayjs from "dayjs";
import FAB from "./FAB";
import { Accordion, AccordionBody } from "@material-tailwind/react";
import { GetUserPath } from "../pages/Home";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faDice,
  faGears,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";

export interface GenerateTabProps {
  user: User | null;
  updateWeeks: (weeks: Week[]) => void;
  sharedWeeks: Week[];
}

const GenerateTab: FC<GenerateTabProps> = ({
  user,
  updateWeeks = () => {},
  sharedWeeks,
}) => {
  const options: any = {
    headers: {
      "Content-Type": "application/json",
    },
    cachePolicy: "no-cache",
  };
  const { get, post, put, response, loading, error } = useFetch(
    `${Config.ApiUrl()}`,
    options,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    generate();
  }, [user, sharedWeeks]);

  const [selectedID, setSelectedID] = useState<string>("");

  const generate = async () => {
    const weeks = await get(`${GetUserPath(user)}/generate`);
    if (weeks) {
      setWeeks(weeks);
    }
  };

  const shuffle = async () => {
    const week = await put(`${GetUserPath(user)}/weeks/shuffle`, weeks[0]);
    if (week && response.status === 200) {
      setWeeks([week]);
    }
    if (response.status > 400) {
      console.log(response, week);
    }
  };

  const swapRecipe = async (
    weekId: string,
    day: Day,
    weekIndex: number,
    dayIndex: number,
  ) => {
    const data = await post(
      `${GetUserPath(user)}/weeks/${weekId}/suggest`,
      day,
    );
    if (data) {
      const newRes = data;
      const oldDay = weeks[weekIndex].days[dayIndex];
      oldDay.dinner = newRes;

      const updatedWeek = [...weeks];
      updatedWeek[weekIndex].days[dayIndex] = oldDay;

      setWeeks(updatedWeek);
    }
  };

  const save = async () => {
    console.log(weeks);
    const data = await post(`${GetUserPath(user)}/weeks`, weeks);
    if (data) {
      if (response.status === 201) {
        generate();
        updateWeeks(data);
      }
    }
  };

  const [weeks, setWeeks] = useState<Week[]>([]);
  return (
    <>
      <TabPanel className="p-0" value="generate">
        <div className="flex items-centered h-8 absolute top-6 right-6">
          {loading && <span className="my-auto">Generating...</span>}
        </div>
        {error && <p>Error! {JSON.stringify(error)}</p>}
        {weeks && weeks.length === 0 && (
          <p>
            Failed to get suggestions. Please contact the maintainer for help
          </p>
        )}
        {weeks &&
          Array.isArray(weeks) &&
          weeks.map((week: Week, weekIndex: number) => {
            return (
              <div
                key={week.id}
                className="h-[calc(100vh-200px)] flex flex-col justify-around"
              >
                <div className="flex justify-between px-4 mt-2">
                  <p className="text-2xl font-bold pr">
                    Suggestion: V.{week.number} ({week.year})
                  </p>
                </div>
                {week.days.map((day: Day, dayIndex: number) => {
                  const date = dayjs(day.date);
                  return (
                    <Accordion key={day.id} open={selectedID === day.id}>
                      <div
                        className="flex flex-col justify-between active:bg-gray-200 px-4 py-2"
                        onClick={() => {
                          setSelectedID(day.id);
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <span className="text-xl font-bold">
                            {date.format("dddd")}
                          </span>
                          <span className="text-sm text-gray-500">
                            {date.format("YYYY-MM-DD")}
                          </span>
                        </div>
                        <p className="ml-3">{day.dinner.name}</p>
                      </div>
                      <AccordionBody className="bg-gray-100 px-4 py-2 flex items-center justify-between">
                        <p>Portions: {day.dinner.portions}</p>
                        <div className="flex flex-col">
                          <p>Portions: {day.dinner.portions}</p>
                          {day.dinner.url && (
                            <a
                              title={day.dinner.url}
                              href={day.dinner.url}
                              target="_blank"
                              className="text-blue-500 block hover:underline"
                            >
                              {day.dinner.url.substring(0, 24)}...
                            </a>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            swapRecipe(week.id, day, weekIndex, dayIndex);
                          }}
                        >
                          <Button className="bg-blue-400">
                            New suggestion?
                          </Button>
                        </div>
                      </AccordionBody>
                    </Accordion>
                  );
                })}
                <div className="flex flex-col justify-between absolute right-6 bottom-6 w-14">
                  <FAB
                    onClick={() => {
                      shuffle();
                    }}
                    className="bg-gray-500 h-14 w-14 mb-4"
                  >
                    <FontAwesomeIcon className="text-2xl" icon={faDice} />
                  </FAB>
                  <FAB
                    onClick={() => {
                      generate();
                    }}
                    className="bg-gray-500 h-14 w-14 mb-4"
                  >
                    <FontAwesomeIcon className="text-2xl" icon={faGears} />
                  </FAB>
                  <FAB
                    onClick={() => {
                      save();
                    }}
                    className="bg-green-500 h-14 w-14"
                  >
                    <FontAwesomeIcon className="text-2xl" icon={faFloppyDisk} />
                  </FAB>
                </div>
              </div>
            );
          })}
      </TabPanel>
    </>
  );
};

export default GenerateTab;
