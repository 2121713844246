import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Button,
  Spinner,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Config } from "../config";
import useFetch from "use-http";
import EyeIcon from "../components/icons/EyeIcon";
import EyeSlashIcon from "../components/icons/EyeSlashIcon";

const Login = () => {
  const options: any = {
    headers: {
      "Content-Type": "application/json",
    },
    cachePolicy: "no-cache",
  };
  // @ts-ignore
  const { post, loading, error, response } = useFetch(
    `${Config.Url()}`,
    options,
  );

  useEffect(() => {
    console.log(response);
  }, [response]);

  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [username, setUsername] = useState<string>(() => {
    const username = localStorage.getItem("username");
    if (username) {
      return username;
    }
    return "";
  });

  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(() => {
    const rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe) {
      return rememberMe === "true";
    }
    return false;
  });

  const storeUsername = (user = username, remember = rememberMe) => {
    if (remember) {
      localStorage.setItem("username", user);
    } else {
      localStorage.removeItem("username");
    }
  };

  const signIn = () => {
    const loginCredentials = {
      username: username,
      password: password,
    };
    setMessage("");
    post("/login", loginCredentials).then(() => {
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        window.location.href = "/home";
      } else if (response.status === 401) {
        setMessage("Invalid username or password");
      }
    }).catch((e) => {
      console.log('login failed ', e);
      setMessage("Login failed");
    });
  };

  useEffect(() => {
    if (error) {
        setMessage("Login failed: check your internet connection");
    }
  }, [error]);


  return (
    <div className="grid h-screen place-items-center">
      <Card className="w-96 mx-auto">
        <CardHeader
          variant="gradient"
          color="green"
          className="mb-4 grid h-28 place-items-center"
        >
          <Typography variant="h3" color="white">
            Mealshuffler
          </Typography>
        </CardHeader>
        <CardBody className="flex flex-col gap-4">
          <Input
            label="Username"
            size="lg"
            crossOrigin={undefined}
            value={username}
            onChange={(e: any) => {
              setUsername(e.target.value);
              storeUsername(e.target.value);
            }}
          />
          <div className="flex relative items-centered">
            <Input
              type={togglePassword ? "text" : "password"}
              label="Password"
              size="lg"
              crossOrigin={undefined}
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  signIn();
                }
              }}
              className="h-12"
            />
            <div className="absolute right-0 mr-2.5 h-12 grid place-items-center">
              <div
                className=""
                onClick={() => setTogglePassword(!togglePassword)}
              >
                {togglePassword ? <EyeIcon /> : <EyeSlashIcon />}
              </div>
            </div>
          </div>
          <div className="flex">
            {message && (
              <p className="text-red-500 text-sm font-bold">{message}</p>
            )}
          </div>
          <div className="-ml-2.5">
            <Checkbox
              label="Remember Me"
              crossOrigin={undefined}
              checked={rememberMe}
              onChange={(e: any) => {
                setRememberMe(e.target.checked);
                storeUsername(undefined, e.target.checked);
                localStorage.setItem("rememberMe", e.target.checked.toString());
              }}
            />
          </div>
        </CardBody>
        <CardFooter className="pt-0">
          <Button
            variant="gradient"
            color="green"
            onClick={() => signIn()}
            className="flex items-center justify-center h-12"
            fullWidth
          >
            {loading && (
              <span className="mr-2">
                <Spinner />
              </span>
            )}
            Sign In
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};
export default Login;
