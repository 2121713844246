import { Button, Card, Input, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import useFetch from "use-http";
import { Config } from "../config";

const Admin = () => {
  const options: any = {
    headers: {
      "Content-Type": "application/json",
    },
    cachePolicy: "no-cache",
  };
  // @ts-ignore
  const { get, post, put, del, loading, error, response } = useFetch(
    `${Config.ApiUrl()}/admin`,
    options,
  );

  useEffect(() => {
    get("/ping");
  }, []);

  const [newUser, setNewUser] = useState({
    name: "",
    username: "",
    password: "",
  });
  const [serverStatus, setServerStatus] = useState("<waiting for request>");

  const addUser = async () => {
    const res = await post("/users", newUser);
    if (response.status === 201) {
      setServerStatus("<user created>");
      return;
    }
    setServerStatus("<user not created got>: " + JSON.stringify(res))
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col text-xl mx-auto mt-6 h-48">
        <span>Server status:</span> 
        <span className="text-md"> {serverStatus}</span>
      </div>
      <Card className="mx-auto" color="transparent" shadow={false}>
        <Typography variant="h4" color="blue-gray">
          Register new user
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Enter your details to register.
        </Typography>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-4 flex flex-col gap-6">
            <Input
              size="lg"
              value={newUser.name}
              label="Name"
              crossOrigin={undefined}
              onChange={(e) => {
                setNewUser({ ...newUser, name: e.target.value });
              }}
            />
            <Input
              size="lg"
              value={newUser.username}
              label="Username"
              crossOrigin={undefined}
              onChange={(e) => {
                setNewUser({ ...newUser, username: e.target.value });
              }}
            />
            <Input
              value={newUser.password}
              type="password"
              size="lg"
              label="Password"
              crossOrigin={undefined}
              onChange={(e) => {
                setNewUser({ ...newUser, password: e.target.value });
              }}
            />
          </div>
          <Button className="mt-6" fullWidth onClick={() => addUser()}>
            Register
          </Button>
        </form>
      </Card>
    </div>
  );
};
export default Admin;
