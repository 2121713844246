import { useEffect, useState } from "react";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
} from "@material-tailwind/react";
import WeeksTab from "../components/WeeksTab";
import RecipeIcon from "../components/icons/RecipeIcon";
import GenerateIcon from "../components/icons/GenerateIcon";
import WeekIcon from "../components/icons/WeekIcon";
import GenerateTab from "../components/GenerateTab";
import { User, Week } from "../domain";
import UserCard from "../components/UserCard";
import RecipeTab from "../components/RecipeTab";
export const GetUserPath = (user: User | null) => {
  if (user === null) {
    window.location.href = "/login";
    return;
  }
  return `/users/${user.id}`;
};

const Home = () => {
  const [activeTab, setActiveTab] = useState<"weeks" | "generate" | "recipes">(
    () => {
      const tab = localStorage.getItem("activeTab");
      if (tab) {
        return tab as "weeks" | "generate" | "recipes";
      }
      return "weeks";
    },
  );
  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);
  const [user, _] = useState<User | null>(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      return user;
    }
    return null;
  });
  const [weeks, setWeeks] = useState<Week[]>([]);
  const [sharedWeeks, setSharedWeeks] = useState<Week[]>([]);
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex items-center justify-around ">
        <p className="text-3xl mx-auto py-3 h-14 px-5 rounded">Mealshuffler</p>
        <div className="absolute top-0 h-14">
          <UserCard user={user} />
        </div>
      </div>
      <Tabs color="indigo" value={activeTab}>
        <TabsHeader
          className="bg-white rounded-none border-b border-blue-gray-50"
          indicatorProps={{
            className:
              "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
          }}
        >
          <Tab
            className={activeTab === "weeks" ? "text-gray-900" : ""}
            value="weeks"
            onClick={() => setActiveTab("weeks")}
          >
            <div className="flex items-center gap-2">
              <WeekIcon />
              <span>Weeks</span>
            </div>
          </Tab>
          <Tab
            className={activeTab === "generate" ? "text-gray-900" : ""}
            value="generate"
            onClick={() => setActiveTab("generate")}
          >
            <div className="flex items-center gap-2">
              <GenerateIcon />
              <span>Generate</span>
            </div>
          </Tab>
          <Tab
            className={activeTab === "recipes" ? "text-gray-900" : ""}
            value="recipes"
            onClick={() => setActiveTab("recipes")}
          >
            <div className="flex items-center gap-2">
              <RecipeIcon />
              <span>Recipes</span>
            </div>
          </Tab>
        </TabsHeader>
        <TabsBody>
          <WeeksTab
            user={user}
            initialWeeks={weeks}
            updateSharedWeeks={(weeks: Week[]) => {
              setSharedWeeks(weeks);
            }}
          />
          <GenerateTab
            sharedWeeks={sharedWeeks}
            user={user}
            updateWeeks={(nWeeks: Week[]) => {
              const updatedWeeks = [...sharedWeeks, ...nWeeks];
              setWeeks(updatedWeeks);
              setSharedWeeks(updatedWeeks);
            }}
          />
          <RecipeTab user={user} />
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default Home;
