import { useEffect } from "react";
import { Config } from "../config";

const Start = () => {
  const navTo = (str: string) => {
    window.location.href = str;
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log(token);
    if (!token) {
      navTo("/login");
      return;
    }
    const user = localStorage.getItem("user");
    console.log(user);
    if (!user) {
      navTo("/login");
      return;
    }
    fetch(`${Config.ApiUrl()}/ping`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          navTo("/home");
          return;
        }
        navTo("/login");
        return;
      })
      .catch((err) => {
        console.log(err);
        navTo("/login");
        return;
      });
  }, []);

  return (
    <div className="grid h-screen place-items-center">
      <div className="text-4xl">
        Mealshuffler is <span className="block">Loading...</span>
      </div>
    </div>
  );
};

export default Start;
